import { ColumnType } from './columns';

export interface AdminDevModel {
  email: string;
  fname: string;
  lname: string;
  admin: boolean;
  last_login: number;
  creation_date: number;
  org: string;
  disabled: boolean;
  systems: AdminDevSystemModel;
}

export interface AdminDevSystemModel {
  [appid: string]: string;
}

export interface AdminDevUpdatedModel {
  admin: boolean;
  creation_date: number;
  disabled: boolean;
  email: string;
  fname: string;
  last_login: number;
  lname: string;
  org: string;
  password: null;
  password_hash: string;
  salt: string;
  userid: string;
}

export enum AdminDevColumns {
  EMAIL = 'email',
  FNAME = 'fname',
  LNAME = 'lname',
  ORG = 'org',
  LAST_LOGIN = 'last_login',
  CREATION_DATE = 'creation_date',
  SYSTEMS = 'systems',
  ADMIN = 'admin',
  DISABLED = 'disabled',
}

export const allAdminDevColumns: AdminDevColumns[] = Object.values(AdminDevColumns);

export enum AdminAssetTypes {
  devs = 'developers',
  systems = 'systems',
  licenseManagement = 'licenseManagement',
  auditTrail = 'auditTrail',
  security = 'security',
  fileManagement = 'fileManagement',
  edgeAutoUpgrade = 'edgeAutoUpgrade',
  throttling = 'throttling',
}

export interface AdminSystemModel {
  name: string;
  // numAPIReqsMonth: number;
  // numAPIReqsTotal: number;
  // numAPIReqsYear: number;
  numDevs: number;
  developers: string[];
  numServices: number;
  numLibraries: number;
  numAdapters: number;
  numPortals: number;
  numPlugins: number;
  numDeployments: number;
  numRoles: number;
  numCollections: number;
  numDevices: number;
  numEdges: number;
  // numPub: number;
  // numPubMonth: number;
  // numPubYear: number;
  // numRecMonth: number;
  // numRecTotal: number;
  // numRecYear: number;
  numUsers: number;
  // diskUsage: number;
  owner: string;
  systemKey: string;
  disabled: boolean;
  lastUpdated: number;
}

export enum AdminSystemInfo {
  NAME = 'name',
  OWNER = 'owner',
  SYS_KEY = 'systemKey',
  DISABLED = 'disabled',
  LAST_UPDATED = 'lastUpdated',
  // DISK_USAGE = 'diskUsage',
  NUM_DEVS = 'numDevs',
}

export enum AdminSystemAssets {
  NUM_SERVICES = 'numServices',
  NUM_LIBRARIES = 'numLibraries',
  NUM_ADAPTERS = 'numAdapters',
  NUM_PORTALS = 'numPortals',
  NUM_PLUGINS = 'numPlugins',
  NUM_DEPLOYMENTS = 'numDeployments',
  NUM_ROLES = 'numRoles',
  NUM_COLLS = 'numCollections',
  NUM_DEVICES = 'numDevices',
  NUM_EDGES = 'numEdges',
  NUM_USERS = 'numUsers',
}

export enum AdminSystemApis {}
// API_TOTAL = 'numAPIReqsTotal',
//   API_PER_MO = 'numAPIReqsMonth',
//   API_PER_YEAR = 'numAPIReqsYear',

export enum AdminSystemMessages {}
// PUB_TOTAL = 'numPub',
// PUB_PER_MO = 'numPubMonth',
// PUB_PER_YEAR = 'numPubYear',
// REC_TOTAL = 'numRecTotal',
// REC_PER_MO = 'numRecMonth',
// REC_PER_YEAR = 'numRecYear',

export type AdminSystemColumn = AdminSystemInfo | AdminSystemAssets;
export const allAdminSystemColumns: AdminSystemColumn[] = [
  ...Object.values(AdminSystemInfo),
  ...Object.values(AdminSystemAssets),
  // ...Object.values(AdminSystemApis),
  // ...Object.values(AdminSystemMessages),
];

export interface DisableSystemReturn {
  api: number;
  appid: string;
  appsecret: string;
  auth_service: string;
  description: string;
  disk: number;
  message_publish: number;
  message_receive: number;
  name: string;
  reg_service: string;
  registration: string;
  token_ttl: number;
  userid: string;
}

enum Audit_user_type {
  'UNKNOWN' = 'UNKNOWN',
  'DEV' = 'DEV',
  'USER' = 'USER',
  'DEVICE' = 'DEVICE',
  'Invalid UserType' = 'Invalid UserType',
}

export const UserTypeAuditOptions = Object.keys(Audit_user_type).map((d) => ({
  name: d,
  value: d,
}));

enum Audit_action_type {
  'Create' = 'Create',
  'Update' = 'Update',
  'Delete' = 'Delete',
  'Create Columns' = 'Create Columns',
  'Delete Columns' = 'Delete Columns',
  'Developer Password Reset' = 'Developer Password Reset',
  'User Password Reset' = 'User Password Reset',
  'MQTT Disconnect' = 'MQTT Disconnect',
  'MQTT Connect' = 'MQTT Connect',
}

export const ActionTypeAuditOptions = Object.keys(Audit_action_type).map((d) => ({
  name: d,
  value: d,
}));

enum Audit_asset_class {
  'adaptors' = 'adaptors',
  'adaptorFiles' = 'adaptorFiles',
  'devices' = 'devices',
  'users' = 'users',
  'multi_dev' = 'multi_dev',
  'services' = 'services',
  'libraries' = 'libraries',
  'servicecaches' = 'servicecaches',
  'timers' = 'timers',
  'triggers' = 'triggers',
  'webhooks' = 'webhooks',
  'portals' = 'portals',
  'plugins' = 'plugins',
  'rolesperms' = 'rolesperms',
  'collections' = 'collections',
  'edges' = 'edges',
  'messaging' = 'messaging',
  'deployments' = 'deployments',
  'edgegroups' = 'edgegroups',
  'system' = 'system',
  'usersessions' = 'usersessions',
  'devicesessions' = 'devicesessions',
}

export const AssetClassAuditOptions = Object.keys(Audit_asset_class).map((d) => ({
  name: d,
  value: d,
}));

export interface AdminAuditItem {
  user_type: Audit_user_type;
  action_type: Audit_action_type;
  asset_class: Audit_asset_class;
  asset_id: string;
  changes: string;
  email: string;
  id: number;
  response_time: number;
  system_key: string;
  time: string;
}

export const AdminAuditColumns = [
  { ColumnName: 'action_type', ColumnType: ColumnType.string, PK: false },
  { ColumnName: 'user_type', ColumnType: ColumnType.string, PK: false },
  { ColumnName: 'asset_class', ColumnType: ColumnType.string, PK: false },
  { ColumnName: 'asset_id', ColumnType: ColumnType.string, PK: false },
  { ColumnName: 'changes', ColumnType: ColumnType.string, PK: false },
  { ColumnName: 'email', ColumnType: ColumnType.string, PK: false },
  { ColumnName: 'id', ColumnType: ColumnType.int, PK: true },
  { ColumnName: 'response_time', ColumnType: ColumnType.int, PK: false },
  { ColumnName: 'system_key', ColumnType: ColumnType.string, PK: false },
  { ColumnName: 'time', ColumnType: ColumnType.timestamp, PK: false },
];
